import React from "react"
import styled from "styled-components"
import { FaCheckCircle } from "react-icons/fa"
import { SIGN_UP_LINK } from "../../constants/linkConstants"
import Features, { USERS_LABEL } from "./features"
import { Col } from "react-grid-system"
import MostPopularBadge from "./mostPopularBadge"

const OuterPriceWrapper = styled.div`
  background-color: white;
  border-radius: 12px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
  min-width: 240px;
  max-width: 240px;
  width: 100%;
  border: ${props =>
    props.bigger ? "5px solid var(--clr-ripe-mango)" : "none"};
  padding: 2px 20px 10px;
  position: relative;
`

const LabelText = styled.h3`
  color: ${props =>
    props.bigger ? "var(--clr-dodger-blue)" : "var(--clr-spanish-gray)"};
  font-size: ${props => (props.bigger ? "28px" : "23px")};
  font-weight: ${props => (props.bigger ? 600 : 500)};
  text-align: center;
  letter-spacing: 0.05rem;
  margin-bottom: 0;
`

const LabelTextWrapper = styled.div`
  border-bottom: 1px solid var(--clr-platinum);
  margin-bottom: 0px;
  padding: ${({ bigger }) => (bigger ? "20px" : "15px")} 0px;
  margin-bottom: 15px;
`

const PriceColumn = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
`

const PriceText = styled.h3`
  color: var(--clr-white);
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.05rem;
  margin: 0px;
`

const SubText = styled.span`
  font-size: 12px;
  color: var(--clr-white);
  font-weight: 300;
  letter-spacing: 0.01rem;
`

const SubscribeLink = styled.button`
  min-height: 52px;
  background-color: ${({ priceButton, freeButton }) =>
    (priceButton && "var(--clr-dodger-blue)") ||
    (freeButton && "grey") ||
    "var(--clr-green-medium-dark)"};
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-white);
  border: none;
  width: 100%;
  border-radius: 8px;
  margin-bottom: 15px;
  letter-spacing: 0.05rem;
`

const CheckIcon = ({ enabled }) => {
  const className = enabled ? "icon-enabled" : "icon-disabled"
  return <FaCheckCircle className={className} style={{ marginRight: 10 }} />
}

const RowText = ({ feature, users }) => (
  <p
    style={{
      fontSize: 12,
      marginBottom: 0,
    }}
  >
    {feature.label === USERS_LABEL ? `${users} Users` : feature.label}{" "}
  </p>
)

const BoldRowText = ({ feature, users }) => (
  <p
    style={{
      fontSize: 12,
      marginBottom: 0,
    }}
  >
    <b>{feature.label === USERS_LABEL ? `${users} Users` : feature.label} </b>
  </p>
)

const getRows = (idProp, users) =>
  Features.map((feature, i) => {
    const enabled = feature[idProp] === true
    const boldUserPlan =  idProp === "enterprise"
    const boldUsers = feature.label === USERS_LABEL && boldUserPlan
    return (
      <div
        key={`${feature.label}${i}`}
        style={{
          display: "flex",
          alignItems: "center",
          height: 25,
          width: "100%",
        }}
      >
        {enabled ? <CheckIcon enabled /> : <CheckIcon />}
        {boldUsers ? (
          <BoldRowText feature={feature} users={users} />
        ) : (
          <RowText feature={feature} users={users} />
        )}
      </div>
    )
  })

const PriceItem = props => (
  <OuterPriceWrapper small={props.small} bigger={props.bigger}>
    {props.bigger ? <MostPopularBadge /> : null}
    <LabelTextWrapper bigger={props.bigger}>
      <LabelText bigger={props.bigger}>{props.label}</LabelText>
    </LabelTextWrapper>
    <PriceColumn>
      {getRows(props.idProp, props.users, props.boldUsers)}
    </PriceColumn>
    <div>
      <a href={props.signupLink || SIGN_UP_LINK} id={`signup_${props.idProp} `}>
        <SubscribeLink priceButton={!props.free} freeButton={props.free}>
          {(props.free && "Free") || (
            <Col
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <PriceText>{props.price}</PriceText>
              <SubText>{props.priceText}</SubText>
            </Col>
          )}
        </SubscribeLink>
      </a>
      <a href={props.signupLink || SIGN_UP_LINK} id={`signup_${props.idProp} `}>
        <SubscribeLink>Get Started</SubscribeLink>
      </a>
    </div>
  </OuterPriceWrapper>
)

export default PriceItem
